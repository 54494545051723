import $ from "jquery";
import { fetchWithAuthAndParseResponse, ResponseError } from "../../../../common/webpack/shared/fetchWithAuth";
import { InternalOnlyError } from "../../../../common/webpack/shared/utils/errors";

class SessionInactivity {
  constructor(opts) {
    this.statusURL = opts.statusURL;
    this.warnAfterSeconds = opts.warnAfterSeconds;
    this.setGetStatusTimer(this.warnAfterSeconds);
    this.$warningOverlay = $(opts.warningOverlayElementSelector);

    // eslint-disable-next-line i18next/no-literal-string
    for (const event of ["click", "keyup"]) {
      document.addEventListener(event, () => {
        return this.userPerformedActivity();
      });
    }
  }

  setGetStatusTimer = (numSeconds) => {
    /*
      Sets a timeout to pull the status URL after X seconds.
      As a result, the UI will be redrawn and new timers will be set.
    */
    if (this.timeoutID != null) {
      clearTimeout(this.timeoutID);
    }

    const getStatusFn = () => {
      fetchWithAuthAndParseResponse(this.statusURL, this.render).then((response) => {
        if (response instanceof ResponseError && response.code === 401) {
          document.location = "/logout";
        }
      });
    };

    // Make sure we don't accidentally set a timeout of zero.
    const timeoutInterval = numSeconds > 1 ? numSeconds : 1;
    this.timeoutID = setTimeout(getStatusFn, timeoutInterval * 1000.0 + 1000.0);
  };

  userPerformedActivity = () => {
    // If we have a short timeout, use a 5-second interval, otherwise 1 minute.
    const minTimeBewtweenStatusUpdatesInterval = this.warnAfterSeconds < 60 ? 5 * 1000.0 : 60 * 1000.0;
    if (!this.lastActivity || new Date() - this.lastActivity > minTimeBewtweenStatusUpdatesInterval) {
      this.lastActivity = new Date();
      fetchWithAuthAndParseResponse(this.statusURL, this.render, { method: "POST" }).catch((e) => {
        throw new InternalOnlyError(`Activity Update Failed. ${e}.`);
      });
    }
  };

  render = (data) => {
    /*
      Using the session's status from the back end, the UI is redrawn entirely.  This can be handy if
      the warning overlay is up, but another tab has updated the session.  This function will see that
      the status has been changed to OK, and remove the warning overlay.

      Expected data: {
        status: (OK | WARN | EXPIRED)
        seconds_until_warn: float
        seconds_until_expire: float
      }
    */

    if (data.status === "EXPIRED") {
      // The next run through the middleware should redirect to the appropriate logout endpoint.
      document.location.reload();
    }
    if (data.status === "OK") {
      // Set a timer to getStatus again
      this.setGetStatusTimer(data.seconds_until_warn);

      // If the warning is displayed, remove it.
      if (this.$warningOverlay.is(":visible")) {
        this.$warningOverlay.hide();
      }
    } else if (data.status === "WARN") {
      this.setGetStatusTimer(data.seconds_until_expire);

      // If the warning isn't displayed, display it.
      if (!this.$warningOverlay.is(":visible")) {
        this.$warningOverlay.fadeIn("slow");
      }
    } else {
      // This happens if the statusURL is accessed after logout, which happens if someone ever
      // opens more than one tab.  Just reload (which should cause logout) instead of
      // throwing an error.
      document.location.reload();
    }
  };
}

document.addEventListener("DOMContentLoaded", () => {
  const dataNode = document.querySelector("#session_inactivity_data");
  // We don't always render the data node (if the user is logged out or session inactivity is off).
  if (dataNode !== null) {
    // eslint-disable-next-line no-new
    new SessionInactivity({
      statusURL: dataNode.dataset.statusUrl,
      warnAfterSeconds: dataNode.dataset.warnAfterSeconds,
      expireAfterSeconds: dataNode.dataset.expireAfterSeconds,
      warningOverlayElementSelector: dataNode.dataset.warningOverlayElementSelector,
    });
  }
});
